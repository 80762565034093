import "../styles/App.css";
import { useNavigate } from "react-router-dom";

function TopNavBarComp(){
    const navigate = useNavigate();

    function goToFAQ(serviceItem){
        navigate("/support");       
    }
    function goToStart(serviceItem){
        navigate("/");       
    }

    return(
        <div className="AppHeader">
            <div className="NavigationWrapper">
                <div className="LogoWrapper">
                   <img onClick={() => goToStart()} className="HeaderLogo" src="/images/logo.svg" alt="Logo" />
                </div>
                <div className="Navigation">
                    <p onClick={() => goToFAQ()}>Kundenservice & FAQ</p>
                </div>
            </div>
           
        </div>
    );
}
export default TopNavBarComp;