import "../styles/App.css";
import TopNavBarComp from "../components/TopNavBarLoggedInComp";
import FooterComp from "../components/FooterComp";

import Background from "../images/Background.jpeg";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import dayjs from 'dayjs';


function KundenPage(){
    const locationKunden = useLocation();
    const navigate = useNavigate()
    const [orderData, setOrderData] = useState([]);
    const [eventData, setEventData] = useState({'eventimVenueRel': {'name': '', 'city': ''}});
   
    const [ticketData, setTicketData] = useState([]);   

    function checkState(){  
        if(locationKunden.state){           
            getOrderdata(locationKunden.state.orderid, locationKunden.state.hash);
        }else{
            console.log('State undefined');
            navigate('/');
        }
    }

    useEffect(() => {
        checkState();
    }, []);
    
    function getOrderdata(orderid, hash){
        fetch(
            '/getData_slim.php',
            {
                method: 'POST',
                mode: 'cors',
                body: "route=customerorder&orderid=" + orderid + "&hash=" + hash + "&method=post",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin':'*'
                }
            }
    
        ).then(function(response) {
            return response.json();
        }).then(function(data) { 
            console.log('eventData', eventData);            
            setOrderData(data[0]);
            setTicketData(data[0].ticketsRel);
            getEventdata(data[0].eventid);
            console.log('orderdata', data[0]);
            
        }).catch(function(error) {
            console.log('Request failed', error)
        });
    }

    function getEventdata(eventid){
        fetch(
            '/getData_slim.php',
            {
                method: 'POST',
                mode: 'cors',
                body: "route=eventByID/3/" + eventid + "&method=get",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin':'*'
                }
            }
    
        ).then(function(response) {
            return response.json();
        }).then(function(data) {            
            setEventData(data['event'][0]);
            console.log('eventdata', data['event'][0]);
            
        }).catch(function(error) {
            console.log('Request failed', error)
        });
    }

    function getTickets(data){
        var mapForm = document.createElement("form");
        mapForm.target = "Map";
        mapForm.method = "POST"; // or "post" if appropriate
        mapForm.action = "/ticketPDF.php";
    
        var mapInput = document.createElement("input");
        mapInput.type = "text";
        mapInput.name = "ticketdata";
        mapInput.value = data;
        mapForm.appendChild(mapInput);
    
        document.body.appendChild(mapForm);
    
        var map = window.open("", "Map", "status=0,title=0,height=600,width=800,scrollbars=1");
    
        if (map) {
            mapForm.submit();
        } else {
            alert('You must allow popups for this map to work.');
        }
    }


   

    return(
        <div className="AppContainer">
            <TopNavBarComp/>
            <div className="MainContentBackgroundKd" 
                style={{backgroundImage: `url(${Background})`}}>
                 <div className="MainContentKunden"> 
                    <div className="KundeWraper">
                        <div className="KundenHeader">
                            <h1>Ihre Bestellung</h1>
                        </div>
                        <div className="KundenBody">                            
                            <div className="EventName">
                                <h2>{eventData.title}</h2>
                            </div>
                            <div className="EventData">
                                <table>
                                    <tr>    
                                        <td>
                                            Veranstaltungsdatum:
                                        </td>
                                        <td>
                                            {dayjs(eventData.date).format("DD.MM.YYYY HH:mm")}
                                        </td>
                                    </tr> 
                                    <tr>    
                                        <td>
                                            Bestellnummer:
                                        </td>
                                        <td>
                                            {orderData.orderid}
                                        </td>
                                    </tr>
                                    <tr>    
                                        <td>
                                            Ort:
                                        </td>
                                        <td>
                                            {eventData.eventimVenueRel.name}, {eventData.eventimVenueRel.city}
                                        </td>
                                    </tr>     
                                    <tr>    
                                        <td>
                                            Gesamtbetrag:
                                        </td>
                                        <td>
                                        {orderData.price} €
                                        </td>
                                    </tr>                                            
                                </table>
                            </div>
                            <div className="EventName">
                                <h2>Ihre Tickets</h2>
                                <p>Ihre Tickets können Sie mit einem klick auf den Button Tickets herunterladen und drucken.</p>
                               
                            </div>
                            <div className="TicketTableWrapper">
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                    <TableRow>
                                        <TableCell>Ticket-Nr</TableCell>
                                        <TableCell align="right">Bereich</TableCell>
                                        <TableCell align="right">Reihe</TableCell>
                                        <TableCell align="right">Platz</TableCell>
                                        <TableCell align="right">Kategorie</TableCell>
                                        <TableCell align="right">Preis</TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {ticketData.map((row) => (
                                        <TableRow
                                        key={row.ticketid}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                        <TableCell component="th" scope="row">
                                            {row.ticketid}
                                        </TableCell>
                                        <TableCell align="right">{row.area}</TableCell>
                                        <TableCell align="right">{row.row}</TableCell>
                                        <TableCell align="right">{row.seat}</TableCell>
                                        <TableCell align="right">{row.tickettypetext}</TableCell>
                                        <TableCell align="right">{row.endpriceticket}</TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            </div>
                           
                            <div className="LoginBodyContentItem">
                                    <button onClick={() => getTickets(orderData.ticketdata)} type="submit">Tickets drucken</button>
                                </div> 
                        </div>
                    </div> 
                                 
                </div>               
            </div>                 
            <FooterComp/>
        </div>
    );
}
export default KundenPage;