import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import StartPage from './pages/StartPage';
import SupportPage from './pages/SupportPage';
import ImpressumPage from './pages/ImpressumPage';
import DatenschutzPage from './pages/DatenschutzPage';
import KundenPage from './pages/KundenPage';

function App() {
  return (
    <div>
    <Router>
      <Routes>
        <Route path="/" element={<StartPage />} />
        <Route path="/kunden" element={<KundenPage />} />      
        <Route path="/support" element={<SupportPage />} />
        <Route path="/impressum" element={<ImpressumPage />} />
        <Route path="/datenschutz" element={<DatenschutzPage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
   
  </div>
  );
}

export default App;
