import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';



const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: '1px solid #ccc',  
    background: '#192c84',
    marginBottom: '10px',
    color: '#fff',
    borderRadius: '12px',
   
  }));
  
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: '#fff' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
    height: '80px'
  }));
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, 0)',
    maxHeight: '380px',
    overflowY: 'scroll',
    [theme.breakpoints.down('md')]: {
      maxHeight: '250px',
    },


    
  }));
  

function FAQAcordionComp(props){
    const [expanded, setExpanded] = React.useState('');
    const { supportTopics } = props;
    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };
  
    return(
      <div>
        {supportTopics.map((supportItem) => (
          <Accordion expanded={expanded === supportItem.qa.id} onChange={handleChange(supportItem.qa.id)}>
              <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                <Typography>{supportItem.qa.headline}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography  dangerouslySetInnerHTML={{ __html: supportItem.qa.answer }}>
                 
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        
       
        
      </div>
    );
}
export default FAQAcordionComp;