import "../styles/App.css";
import { useEffect, useState } from "react";
import TopNavBarComp from "../components/TopNavBarComp";
import FooterComp from "../components/FooterComp";

import Background from "../images/Background.jpeg";
import { useNavigate } from "react-router-dom";

function StartPage(){
    const navigate = useNavigate();
    const [mail, setMail] = useState('');
    const [secret, setSecret] = useState('');
    const [captcha, setCaptcha] = useState('');
    const [captchaImage, setCaptchaImage] = useState('/php/securimage/securimage_show.php');
    //const [captchaImage, setCaptchaImage] = useState('/images/securimage_test.png');
    const [errorTxt, setErrorTxt] = useState('');

   

    function checkCaptcha(){
        fetch(
            './php/checkForm.php',
            {
                method: 'POST',
                mode: 'cors',
                body: "code="+captcha,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin':'*'
                }
            }
    
        ).then(function(response) {
            return response.json();
        }).then(function(data) {        
            console.log(data);
           
            if(data['status'] === 'correct'){
                checkLogin();
            }else{
                setErrorTxt('Bitte geben Sie den richtigen Code aus dem Bild ein.');                
            }
           
        }).catch(function(error) {
            console.log('Request failed', error)
        });
    }

    function checkLogin(){
        fetch(
            '/getData_slim.php',
            {
                method: 'POST',
                mode: 'cors',
                body: "route=checkcustomer&sec=" + secret + "&mail=" + mail + "&method=post",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin':'*'
                }
            }
    
        ).then(function(response) {
            return response.json();
        }).then(function(data) {        
            console.log('checkLogin',data);
            if(data['hash']){
                navigate("/kunden", {state:{hash: data['hash'], orderid: data['OrderID']}});
            }else{
                setErrorTxt('Bitte geben Sie eine gültige Mailadresse und den dazugehörigen Secret-Code ein.');
            }
            
        }).catch(function(error) {
            console.log('Request failed', error)
        });
    }

   

    function refreshCaptcha(){
        setCaptchaImage('/php/securimage/securimage_show.php?' + Math.random());
    }

    return(
        <div className="AppContainer">
            <TopNavBarComp/>
            <div className="MainContentBackground" 
                style={{backgroundImage: `url(${Background})`}}>
                 <div className="MainContent">  
                    <div className="LoginWrapper">
                        <div className="LoginHeader">
                            <h1>Welcome to your LIVE TICKET account</h1>
                        </div>
                        <div className="LoginBody">                                                              
                                                                 
                                <div className="LoginBodyContentItem">                                               
                                    <input type="text" id="username" name="username" placeholder="Ihre Mailadresse" value={mail} onChange={event => setMail(event.target.value)}/>
                                </div>
                                <div className="LoginBodyContentItem">
                                    <input type="text" id="password" name="password" placeholder="Secret-Code" value={secret} onChange={event => setSecret(event.target.value)}/>
                                </div>
                                <div className="LoginBodyContentItem">
                                    <div className="LoginBodyContentCaptchaRow">
                                        <div className="LoginBodyContentCaptchaImageRow">
                                            <img id="captcha" src={captchaImage} alt="CAPTCHA" />
                                            <img className="captchaRefresh" src="php/securimage/images/refresh.png" alt="Refresh" onClick={() => refreshCaptcha()}></img>
                                        </div>
                                        <div className="LoginBodyContentCaptchaTextRow">
                                            <input type="text" id="captcha" name="captcha"  placeholder="Bitte geben Sie den Text aus dem Bild ein." value={captcha} onChange={event => setCaptcha(event.target.value)}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="LoginBodyContentItem">
                                    <p className="errorTxt">{errorTxt}</p>
                                    <button onClick={() => checkCaptcha()} type="submit">Anmelden</button>
                                </div>                                       
                                                           
                        </div>
                    </div>                   
                </div>               
            </div>                 
            <FooterComp/>
        </div>
    );
}
export default StartPage;