import "../styles/App.css";
import { useNavigate } from "react-router-dom";


function FooterComp(){
    const navigate = useNavigate();
    function goToImpressum(){
        navigate("/impressum");
    }
    function goToDS(){
        navigate("/datenschutz");
    }
    return(
        <footer className="FooterContainer">
            <div className="FooterContentWrapper">
                <div className="FooterContentItem">
                    <h3 onClick={() => goToImpressum()}>Impressum</h3>
                </div>

                <div className="FooterContentItem">
                    <h3 onClick={() => goToDS()}>Datenschutz</h3>
                </div>
            </div>
        </footer>
    );
}
export default FooterComp;