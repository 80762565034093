import "../styles/App.css";
import { useNavigate } from "react-router-dom";


function TopNavBarLoggedInComp(){
    const navigate = useNavigate();
    

    function logOut(serviceItem){
        navigate('/kunden', { state: undefined}); 
        navigate('/');    
    }


    return(
        <div className="AppHeader">
            <div className="NavigationWrapper">
                <div className="LogoWrapper">
                   <img className="HeaderLogo" src="/images/logo.svg" alt="Logo" />
                </div>
                <div className="Navigation">
                    <p onClick={() => logOut()}>Abmelden</p>
                </div>
            </div>
           
        </div>
    );
}
export default TopNavBarLoggedInComp;