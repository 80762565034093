import { useEffect, useState } from "react";

import TopNavBarComp from "../components/TopNavBarComp";
import FooterComp from "../components/FooterComp";

import Background from "../images/Background.jpeg";

import "../styles/App.css";

function DatenschutzPage(){
    const [dsgvoTxt, setDSGVOTxt] = useState([]);

    function getDSGVOTxt(){        
        fetch(
            '/getData_slim.php',
            {
                method: 'POST',
                mode: 'cors',
                body: "route=getWebKonfig/3/dsgvo&method=get",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin':'*'
                }
            }
    
        ).then(function(response) {
            return response.json();
        }).then(function(data) {        
            console.log(data);
            setDSGVOTxt(data.Datenschutz);
            
        }).catch(function(error) {
            console.log('Request failed', error)
        });
    }

     useEffect(() => {
        getDSGVOTxt();
        
    }, []);

    return(
        <div className="AppContainer">
        <TopNavBarComp/>
        <div className="MainContentBackground" 
            style={{backgroundImage: `url(${Background})`}}>
             <div className="MainContent"> 
                <div className="RechtlWrapper" dangerouslySetInnerHTML={{ __html: dsgvoTxt }}>
                  
                
                </div>             
            </div>               
        </div>                 
        <FooterComp/>
    </div>
    );
}
export default DatenschutzPage;