import { useEffect, useState } from "react";

import TopNavBarComp from "../components/TopNavBarComp";
import FooterComp from "../components/FooterComp";

import Background from "../images/Background.jpeg";
import FAQAcordionComp from "../components/FAQAccordionComp";

import "../styles/App.css";

function SupportPage(){
    const [supportTopics, setSupportTopics] = useState([]);

    //Supportthemen DB lesen
      function getSupportTopics(){        
        fetch(
            '/getData_slim.php',
            {
                method: 'POST',
                mode: 'cors',
                body: "route=support/3/1&method=get",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin':'*'
                }
            }
    
        ).then(function(response) {
            return response.json();
        }).then(function(data) {        
            console.log(data);
            setSupportTopics(data);
            
        }).catch(function(error) {
            console.log('Request failed', error)
        });
    }

     useEffect(() => {
        getSupportTopics();
        
    }, []);
    return(
        <div className="AppContainer">
            <TopNavBarComp/>
            <div className="MainContentBackground" 
                style={{backgroundImage: `url(${Background})`}}>
                <div className="MainContent">            
                    <div className="SupportWrapper">
                        <div className="SupportHeader">
                            <h1>Kundenservice & FAQ</h1>
                        </div>
                        <div className="SupportBody">
                            <FAQAcordionComp supportTopics={supportTopics}/>     
                        </div>
                    </div>     
                </div>    
            </div>
            <FooterComp/>
        </div>
    );
}
export default SupportPage;